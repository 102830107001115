import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Funnel, FunnelStage} from './_models'
import {request} from '../../../services/crm-api/request'
import {OpportunitiesQueryResponse} from '../../cards/core/_models'

const getFunnels = async (query: string): Promise<Array<Funnel>> => {
  return request(
    {
      method: 'GET',
      url: `/funnels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getFunnelStages = async (funnel: any, query: string): Promise<Array<FunnelStage>> => {
  return request(
    {
      method: 'GET',
      url: `/funnels/${funnel}/funnel-steps?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const createFunnelStage = (
  funnel: number,
  dataCreate: FunnelStage
): Promise<FunnelStage | undefined> => {
  return request(
    {
      method: 'post',
      url: `/funnels/${funnel}/funnel-steps`,
      data: dataCreate,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<FunnelStage>) => response.data)
}

const updateFunnelStage = (
  funnel: number,
  dataUpdate: FunnelStage
): Promise<FunnelStage | undefined> => {
  return request(
    {
      method: 'patch',
      url: `/funnels/${funnel}/funnel-steps/${dataUpdate.id}`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<FunnelStage>) => response.data)
}

const deleteFunnelStage = (funnel: number, funnelStageId: number): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/funnels/${funnel}/funnel-steps/${funnelStageId}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

const moveFunnelStage = (
  funnel: number,
  funnelStageId: number,
  newPosition: number
): Promise<void> => {
  return request(
    {
      method: 'post',
      url: `/funnels/${funnel}/funnel-steps/${funnelStageId}/move-to/${newPosition}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?status=1&${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}
/*
const getTerminationReasons = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/termination-reasons?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}
*/

const getTerminationSubReasons = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/termination-reasons?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getGlobalSearch = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/search?search=${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getCards = async (type: number, query: string): Promise<OpportunitiesQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=${type}&include_contact=true&include_company=true&include_labels=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const getCardsFunnel = async (funnel: number, query: string = ''): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/funnels/${funnel}/steps-and-cards?include_contact=true&include_company=true&include_labels=true&include_responsible=true${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}
const getPossibleResponsiblesFilter = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users/all?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}
const getExportFunnel = async (funnel: number, query: string = ''): Promise<any> => {
  return request(
    {
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/funnels/${funnel}/export-funnel-report${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d)
}
export {
  getFunnels,
  getFunnelStages,
  createFunnelStage,
  updateFunnelStage,
  getUsers,
  // getTerminationReasons,
  deleteFunnelStage,
  moveFunnelStage,
  getGlobalSearch,
  getCards,
  getCardsFunnel,
  getPossibleResponsiblesFilter,
  getExportFunnel,
}
