import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Card, OpportunitiesQueryResponse, CARD_TYPES} from './_models'
import {request} from '../../../services/crm-api/request'
import {Company} from '../../companies/core/_models'
import {Funnel, FunnelStage} from '../../funnel/core/_models'
import {Contact} from '../../contacts/core/_models'

const getCards = async (type: number, query: string): Promise<OpportunitiesQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=${type}&include_contact=true&include_company=true&include_labels=true&include_funnel=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<OpportunitiesQueryResponse>) => d.data)
}

const getOpportunities = async (query: string): Promise<OpportunitiesQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=${CARD_TYPES.OPPORTUNITY.id}&include_contact=true&include_company=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<OpportunitiesQueryResponse>) => d.data)
}

const getTickets = async (query: string): Promise<OpportunitiesQueryResponse> => {
  //console.log('CARD_TYPES.TICKET.id', CARD_TYPES.TICKET.id)
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=${CARD_TYPES.TICKET.id}&include_contact=true&include_company=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<OpportunitiesQueryResponse>) => d.data)
}

const getCardById = (id: ID, query?: string): Promise<Card | undefined> => {
  //Temporário
  return request(
    {
      method: 'GET',
      url:
        `/cards/${id}?include_funnel=true&include_funnel_step=true&include_contact=true&include_company=true&include_labels=true&include_responsible=true` +
        (query ? '&' + query : ''),
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Card>) => response.data)
}

const createCard = (dataForm: Card): Promise<Card | undefined> => {
  let data = {...dataForm}

  return request(
    {
      method: 'post',
      url: `/cards`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Card>) => response.data)
}

const updateCard = (dataForm: Card): Promise<Card | undefined> => {
  let data = {...dataForm}

  return request(
    {
      method: 'patch',
      url: `/cards/${dataForm.id}`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Card>) => response.data)
}

const deleteCard = (id: ID): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/cards/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

const markWinCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/win-mark`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const markLostCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/lost-mark`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const reopenCard = (id: ID): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/reopen`,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const freezeCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/freeze-mark`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const archiveCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/archive-mark`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const transferCardResponsible = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/transfer-responsible`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const moveCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/move`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const markSolveCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/mark-as-solved`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const markUnsolveCard = (id: ID, data: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/cards/${id}/mark-as-unsolved`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => {
    return response.data
  })
}

const getCompanies = async (query: string): Promise<Response<Array<Company>>> => {
  return request(
    {
      method: 'GET',
      url: `/companies?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<Company>>>) => response.data)
}

const getContacts = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/contacts?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getContactById = (id: ID): Promise<Contact | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/contacts/${id}`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Contact>) => response.data)
}

const getLabels = async (query: string): Promise<Array<any>> => {
  return request(
    {
      method: 'GET',
      url: `/labels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getFunnels = async (query: string): Promise<Array<Funnel>> => {
  return request(
    {
      method: 'GET',
      url: `/funnels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getFunnelStages = async (funnel: number, query: string): Promise<Array<FunnelStage>> => {
  return request(
    {
      method: 'GET',
      url: `/funnels/${funnel}/funnel-steps?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?status=1&${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getTasks = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/tasks?${query}&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const getNotes = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/notes?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const createNote = (dataForm: any): Promise<any> => {
  return request(
    {
      method: 'post',
      url: `/notes`,
      data: dataForm,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<any>) => response.data)
}
const editNote = async (note_id: any, dataForm: any): Promise<any> => {
  return request(
    {
      method: 'patch',
      url: `/notes/${note_id}`,
      data: dataForm,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<any>) => response.data)
}
const deleteNote = (id: ID): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/notes/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}
const getAttachment = async (attachment_id: any): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/attachment/${attachment_id}/download`,
      responseType: 'arraybuffer',
      headers: {
        Accept: '*/*',
      },
    },
    {
      setAuth: true,
    }
  ).then((d: any) => {
    return d
  })
}

const getTicketCategories = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/ticket-categories?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getResponsiblesTransfer = async (
  funnel: number,
  query: string
): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users/responsible/${funnel}?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

export {
  getOpportunities,
  getTickets,
  getCards,
  getCardById,
  createCard,
  updateCard,
  deleteCard,
  markWinCard,
  markLostCard,
  freezeCard,
  transferCardResponsible,
  reopenCard,
  moveCard,
  archiveCard,
  markSolveCard,
  markUnsolveCard,
  getCompanies,
  getLabels,
  getContacts,
  getFunnels,
  getFunnelStages,
  getUsers,
  getTasks,
  getNotes,
  createNote,
  deleteNote,
  editNote,
  getAttachment,
  getTicketCategories,
  getResponsiblesTransfer,
  getContactById,
}
