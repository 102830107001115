/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {deleteFunnelStage, moveFunnelStage} from './core/_requests'

import {useCardFormModalDispatch} from '../cards/core/CardFormModalContext'

import {CARD_TYPES, CARD_STATUS} from '../cards/core/_models'

import {
  moveCard,
  markSolveCard,
  markUnsolveCard,
  deleteCard,
  markWinCard,
  markLostCard,
  reopenCard,
} from '../cards/core/_requests'

import {useFunnel, useFunnelDispatch} from './core/FunnelContext'
import Swal from 'sweetalert2'
import {useCardFreezeModalDispatch} from '../cards/core/CardFreezeModalContext'
import {useCardTransferResponsibleModalDispatch} from '../cards/core/CardTransferResponsibleModalContext'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {Spinner} from 'react-bootstrap'
import {useCardArchiveModalDispatch} from '../cards/core/CardArchiveModalContext'
import {useNavigate} from 'react-router'
import {useCardMarkModalDispatch} from '../cards/core/CardMarkModalContext'
import {TERMINATION_REASON_TYPES} from '../settings/termination-reasons/core/_models'
import FunnelStepDroppable from './FunnelStepDroppable'
import FunnelStepCardDraggable from './FunnelStepCardDraggable'
import {Virtuoso} from 'react-virtuoso'

import CardDropDownActions from '../cards/components/actions/CardDropDownActions'

import {useAuth} from '../../modules/auth'

type PropsFunnelStepColumn = {
  step: any
  setShowModalEdit: any
  setFunnelStageEdit: any
}

const FunnelStepColumn: FC<any> = ({
  step,
  setShowModalEdit,
  setFunnelStageEdit,
}: PropsFunnelStepColumn) => {
  const funnelDispatch = useFunnelDispatch()
  const funnel = useFunnel()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const cardFreezeModalDispatch = useCardFreezeModalDispatch()
  const cardTransferResponsibleModalDispatch = useCardTransferResponsibleModalDispatch()
  const cardArchiveModalDispatch = useCardArchiveModalDispatch()
  const cardMarkModalDispatch = useCardMarkModalDispatch()

  const {currentUser} = useAuth()

  const navigate = useNavigate()

  //console.log('Render FunnelStageColumn', step)

  setTimeout(() => {
    MenuComponent.reinitialization() //Precisa reinicializar os elementos para os dropdowns funcionarem
  }, 500)

  const execDeleteFunnelStage = async (st: any) => {
    try {
      await deleteFunnelStage(funnel.currentFunnel.id, st.id)

      //dispatch delete step
      funnelDispatch({
        type: 'delete-funnel-step',
        data: st,
      })

      Swal.fire('Etapa Excluída!', '', 'success')
    } catch (e) {
      Swal.fire({
        title: 'Opss..',
        text: 'Houve um problema ao excluir o registro.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const confirmDeleteFunnelStage = (op: any) => {
    Swal.fire({
      title: 'Excluir Etapa',
      text: 'Tem certeza que deseja excluir esta etapa? Apagar esta etapa também apagará todos os cards associados, conteúdo e configurações permanentemente.',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        execDeleteFunnelStage(op)
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const execMoveFunnelStep = async (step: any, newPosition: number) => {
    funnelDispatch({
      type: 'start-loading-funnel-steps',
    })

    let oldPosition = step.stepNumber

    try {
      funnelDispatch({
        type: 'move-funnel-step',
        data: {...step, newPosition},
      })

      await moveFunnelStage(funnel.currentFunnel.id, step.id, newPosition)
    } catch (e) {
      console.error(e)

      //Rollback Dispatch
      funnelDispatch({
        type: 'move-funnel-step',
        data: {...step, newPosition: oldPosition},
      })

      Swal.fire({
        title: 'Opss..',
        text: 'Houve um problema ao alterar o registro.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    } finally {
      funnelDispatch({
        type: 'finish-loading-funnel-steps',
      })
    }
  }

  const execMoveCard = async (op: any, new_step: any) => {
    try {
      let oldStepId = op.funnelStepId
      let newCardData = {...op, funnelStepId: new_step}

      funnelDispatch({
        type: 'move-step-card',
        data: newCardData,
        oldStepId: oldStepId,
      })

      await moveCard(op.id, {
        funnelId: op.funnelId,
        stepId: new_step,
      })
    } catch (e) {
      console.error(e)

      let newCardData = {...op}
      funnelDispatch({
        type: 'move-step-card',
        data: newCardData,
        oldStepId: new_step,
      })

      Swal.fire({
        title: 'Opss..',
        text: 'Houve um problema ao mover.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const loadCards = (op_id: any, index: any) => {
    let opps: any = null
    let auxFindOpps = funnel.currentStepsCards.find((e: any) => e.stepId == step.id)
    if (auxFindOpps) opps = auxFindOpps.cards.find((e: any) => e.id == op_id)

    if (!opps) return <></>
    opps.funnel = funnel.funnels.find((e: any) => e.id == opps.funnelId)
    let className = 'funnel-stage-card card'
    switch (opps.status) {
      case CARD_STATUS.WIN.id:
        className = `${className} success-wl-custom-collor`
        break
      case CARD_STATUS.LOST.id:
        className = `${className} danger-wl-custom-collor`
        break
      case CARD_STATUS.SOLVE.id:
        className = `${className} success-wl-custom-collor`
        break
      case CARD_STATUS.UNSOLVE.id:
        className = `${className} danger-wl-custom-collor`
        break
      case CARD_STATUS.FREEZE.id:
        className = `${className} info-wl-custom-collor`
        break
      case CARD_STATUS.ARCHIVE.id:
        className = `${className} info-wl-custom-collor`
        break
      default:
        className = `${className} `
    }

    return (
      <div
        key={opps.id}
        className={className}
        style={{
          cursor: 'pointer',
        }}
      >
        <FunnelStepCardDraggable
          card={opps}
          onClick={(e: any) => {
            if (e?.target?.tagName == 'DIV') {
              cardFormModalDispatch({
                type: 'open-detail-modal',
                data: {id: opps.id, type: opps.type, funnelId: opps.funnelId},
              })

              navigate(`/funnel?card=${opps.id}`)
            }
          }}
        >
          <div
            className='funnel-stage-card-header d-flex'
            style={{justifyContent: 'space-between'}}
          >
            <div className='funnel-stage-card-title'>{opps.title}</div>

            <div className='m-0'>
              <button
                disabled={false}
                type='button'
                className='btn btn-sm'
                style={{padding: '0px 5px 5px 5px'}}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='right-start'
              >
                <i className='fa-solid fa-ellipsis-vertical' style={{color: '#606161'}}></i>
              </button>
              <CardDropDownActions card={opps} onExecAction={onExecAction} />
            </div>
          </div>
          <div className='card-body' style={{paddingTop: '0px'}}>
            {opps.company && (
              <div title='Empresa'>
                <i className='fa-regular fa-building' style={{marginRight: '3px'}}></i>
                {opps.company.name}
              </div>
            )}
            {opps.contact && (
              <div title='Contato'>
                <i className='fa-regular fa-id-badge' style={{marginRight: '3px'}}></i>
                {opps.contact.name}
              </div>
            )}
            {opps.labels && (
              <div className='mt-2 d-flex gap-1'>
                {opps.labels.map((label: any, index: number) => (
                  <span
                    key={'rowlabel' + label + index}
                    className='badge badge-square'
                    style={{
                      backgroundColor: label.color,
                      fontSize: '0.85rem',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={label.name}
                  >
                    {label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name}
                  </span>
                ))}
              </div>
            )}
            <hr></hr>

            {opps.responsible && (
              <div title='Responsável'>
                {' '}
                <i className='fa-solid fa-user' style={{marginRight: '3px'}}></i>{' '}
                {opps.responsible.name}
              </div>
            )}

            <div title='Previsão de Fechamento'>
              <i className='fa-regular fa-calendar-alt' style={{marginRight: '3px'}}></i>
              {opps.toCloseDate
                ? new Date(opps.toCloseDate).toLocaleDateString('pt-BR')
                : 'Sem previsão'}
            </div>

            <div className='d-flex' style={{justifyContent: 'space-between'}}>
              <div className='d-flex' style={{flexDirection: 'column'}}>
                {opps.type == CARD_TYPES.OPPORTUNITY.id && (
                  <>
                    <div title='Valor Único'>
                      <i className='fa-solid fa-coins' style={{marginRight: '3px'}}></i>
                      {opps.uniqueValue > 0
                        ? new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(opps.uniqueValue)
                        : '0,00'}
                    </div>
                    <div title='Valor Recorrente'>
                      <i className='fa-solid fa-coins' style={{marginRight: '3px'}}></i>
                      {opps.recurrentValue > 0
                        ? new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(opps.recurrentValue)
                        : '0,00'}
                    </div>
                  </>
                )}
              </div>

              {/*Exibição de Botão temporária para a Poli*/}
              {currentUser?.customerAccount?.id &&
                opps.contact?.integrationId &&
                (1 == currentUser?.customerAccount?.id ||
                  1 == currentUser?.customerAccount?.reseller?.id) && (
                  <div className='btn-open-op-card'>
                    <span
                      className="link-wl-custom-primary-collor"
                      style={{
                        fontWeight: '500',
                        paddingTop: '10px',
                        paddingBottom: '5px',
                      }}
                      onClick={() => {
                        if (window) {
                          window.open(
                            `https://app-spa.poli.digital/chat#${opps.contact.integrationId}`,
                            '_blank'
                          )
                        }
                      }}
                    
                    >
                      <i
                        className='fa-solid fa-arrow-up-right-from-square'
                        style={{marginRight: '3px'}}
                      ></i>{' '}
                      Abrir chat
                    </span>
                    {CARD_TYPES.TICKET.id == opps.type && (
                      <div style={{marginBottom: '10px'}}></div>
                    )}
                  </div>
                )}

              {/*} <div className='btn-open-op-card'>
                <span
                  style={{ color: '#0000FF' }}
                  onClick={() => {
                    cardFormModalDispatch({
                      type: 'open-detail-modal',
                      data: { id: opps.id, type: opps.type, funnelId: opps.funnelId },
                    })
                    navigate(`/funnel?card=${opps.id}`)
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = '#0000c8'
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = '#0000FF'
                  }}
                >
                  Abrir{' '}
                  {CARD_TYPES.OPPORTUNITY.id == opps.type
                    ? ' Oportunidade'
                    : CARD_TYPES.TICKET.id == opps.type
                      ? 'Tícket'
                      : ''}
                </span>
                  </div> */}
            </div>

            <div className='d-flex' style={{justifyContent: 'space-between'}}>
              <div className='d-flex' style={{flexDirection: 'column'}}></div>
              <div className='btn-open-op-card'>
                <span
                  className='link-wl-custom-primary-collor'
                  onClick={() => {
                    cardFormModalDispatch({
                      type: 'open-detail-modal',
                      data: {id: opps.id, type: opps.type, funnelId: opps.funnelId},
                    })
                    navigate(`/funnel?card=${opps.id}`)
                  }}
                  
                >
                  <i className='fa-regular fa-rectangle-list' ></i> Abrir{' '}
                  {CARD_TYPES.OPPORTUNITY.id == opps.type
                    ? ' Oportunidade'
                    : CARD_TYPES.TICKET.id == opps.type
                    ? 'Tícket'
                    : ''}
                </span>
              </div>
            </div>
          </div>
        </FunnelStepCardDraggable>
      </div>
    )
  }

  const onExecAction = (action: string, data: any) => {
    switch (action) {
      case 'reopen-card':
        funnelDispatch({
          type: 'update-step-card',
          data: data,
        })
        break
      case 'delete-card':
        funnelDispatch({
          type: 'delete-step-card',
          data: data,
        })
        break
      case 'card-mark-unsolved':
        funnelDispatch({
          type: 'update-step-card',
          data: data,
        })
        break
      case 'card-mark-solved':
        funnelDispatch({
          type: 'update-step-card',
          data: data,
        })
        break
      case 'card-mark-lost':
        funnelDispatch({
          type: 'update-step-card',
          data: data,
        })
        break
      case 'card-mark-win':
        funnelDispatch({
          type: 'update-step-card',
          data: data,
        })
        break
    }
  }

  let auxFindOpps = funnel.currentStepsCards.find((e: any) => e.stepId == step.id)

  return (
    <div className='funnel-stage card' key={step.id}>
      <FunnelStepDroppable step={step} moveCard={execMoveCard}>
        <div className='stage-header d-flex' style={{flexShrink: '0', flexDirection: 'column'}}>
          <div className='d-flex' style={{justifyContent: 'space-between'}}>
            <div className='stage-title'>{step.title}</div>
            <div className='d-flex'>
              <div style={{marginRight: '5px'}}>
                {auxFindOpps?.statistics ? auxFindOpps.statistics.totalCards : ''} cards
              </div>
              <div className='m-0'>
                <button
                  disabled={false}
                  type='button'
                  className='btn btn-sm'
                  style={{padding: '0px 5px 5px 5px'}}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='right-start'
                >
                  <i className='fa-solid fa-ellipsis-vertical' style={{color: '#606161'}}></i>
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown funnel-stage-card-options'
                  data-kt-menu='true'
                >
                  <div className='px-4 py-4'>
                    <ul>
                      {step.stepNumber > 1 && (
                        <li>
                          <a
                            href='#'
                            onClick={() => {
                              execMoveFunnelStep(step, step.stepNumber - 1)
                            }}
                          >
                            <i className='fa-solid fa-arrow-left' style={{marginRight: '3px'}}></i>{' '}
                            Mover etapa para esquerda
                          </a>
                        </li>
                      )}
                      {funnel.currentFunnel.steps.length != step.stepNumber && (
                        <li>
                          <a
                            href='#'
                            onClick={() => {
                              execMoveFunnelStep(step, step.stepNumber + 1)
                            }}
                          >
                            <i className='fa-solid fa-arrow-right' style={{marginRight: '3px'}}></i>{' '}
                            Mover etapa para direita
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          href='#'
                          onClick={() => {
                            setFunnelStageEdit(step)
                            setShowModalEdit(true)
                          }}
                        >
                          <i className='fa-solid fa-pencil' style={{marginRight: '3px'}}></i> Editar
                          etapa
                        </a>
                      </li>
                      <li>
                        <a
                          href='#'
                          onClick={() => {
                            confirmDeleteFunnelStage(step)
                          }}
                        >
                          <i className='fa-solid fa-trash' style={{marginRight: '3px'}}></i> Excluir
                          etapa
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {funnel.currentFunnel.type == CARD_TYPES.OPPORTUNITY.id && (
            <div
              title={
                auxFindOpps?.statistics
                  ? 'Valor Recorrente: ' +
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(auxFindOpps.statistics.sumRecurrentValue ?? 0) +
                    '\nValor Único: ' +
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(auxFindOpps.statistics.sumUniqueValue ?? 0)
                  : ''
              }
            >
              {auxFindOpps?.statistics
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(auxFindOpps.statistics.sumTotalValue)
                : 'R$ 0,00'}
            </div>
          )}
          <div>
            <hr></hr>
          </div>
        </div>
        <div
          className='card-body card-body-column'
          style={
            {
              /*overflowY: 'auto', overflowX: 'hidden'*/
            }
          }
        >
          {funnel?.loadingFunnelSteps ? (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Spinner variant='primary' style={{position: 'absolute', top: '50%'}} size='sm' />
            </div>
          ) : (
            //auxFindOpps?.cards?.map((op: any, index: number) => loadCards(op.id, index))
            <Virtuoso
              style={{height: '100%', marginRight: '3px'}}
              data={auxFindOpps?.cards}
              itemContent={(index, op) => (
                <div style={{paddingRight: '1.05rem', paddingLeft: '1.25rem'}}>
                  {loadCards(op.id, index)}
                </div>
              )}
            />
          )}
        </div>
      </FunnelStepDroppable>
    </div>
  )
}

export default FunnelStepColumn
