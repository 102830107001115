import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {Card} from '../core/_models'
import {ListLoading} from '../../../modules/view/ListLoading'
import {
  createCard,
  updateCard,
  getLabels,
  getUsers,
  getCompanies,
  getContacts,
  getFunnels,
  getFunnelStages,
  getTicketCategories,
} from '../core/_requests'
import {
  getCustomFormByEntity,
  getDefaultFields,
  getCustomFields,
  getCardCustomForm,
  getCustomFormById,
} from '../../settings/custom-forms/core/_requests'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import Swal from 'sweetalert2'
import debounce from 'debounce-promise'
import {useQuery, useMutation} from '@tanstack/react-query'
import FieldTypeInput from '../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'
import {useCardFormModalDispatch} from '../core/CardFormModalContext'
import {useFunnelDispatch} from '../../funnel/core/FunnelContext'
import BoxEditable from '../../../../_metronic/layout/components/box-editable/BoxEditable'
import {Button, Spinner} from 'react-bootstrap'
import {CARD_TYPES} from '../core/_models'
import {useNavigate} from 'react-router-dom'
import {formatFieldInputDataToView} from '../../../../_metronic/helpers/handle-input-values/formatFieldInputDataToView'
import {formatCustomFieldInputDataToDb} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldInputDataToDb'
import {formatCustomFieldDbDataToInput} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldDbDataToInput'

type Props = {
  isLoading: boolean
  card?: Card
  cardtype?: number
  funnelId?: number
  type: number
  customFormId?: number
}

const editDataSchema = Yup.object().shape({})

const CardsForm: FC<Props> = ({card, cardtype, isLoading, funnelId, type, customFormId}) => {
  const {refetch} = useQueryResponse()
  const funnelDispatch = useFunnelDispatch()
  const navigate = useNavigate()
  const cardFormModalDispatch = useCardFormModalDispatch()

  const handleClose = () => {
    cardFormModalDispatch({
      type: 'close-modal',
    })
    navigate(`${card?.type == CARD_TYPES.OPPORTUNITY.id ? '/opportunities' : '/tickets'}`)
    refetch()
  }

  const {
    isLoading: systemFormIsLoading,
    isError: systemFormIsError,
    data: systemFormData,
    error: systemFormError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'details', 'card', 'customform', customFormId],
    queryFn: async () => {
      let dataRes: any = null
      if (funnelId) {
        //Necessário estar na tela de funil para buscar o formulário de sistema do card
        if (customFormId) {
          dataRes = await getCustomFormById(customFormId)
        }
        // else
        // {

        //   dataRes = await getCardCustomForm('card', funnelId, type)
        // }

        if (dataRes && dataRes?.fields?.length >= 0) {
          let workFields: any = []
          dataRes?.fields?.forEach((e: any) => {
            if (e.customFieldId) {
              let options = e.customField?.options ? e.customField.options : []
              workFields.push({...e, name: 'custom_' + e.customFieldId, options: options})
            } else {
              workFields.push(e)
            }
          })

          dataRes.fields = workFields
        }
      }

      return dataRes
    },
    enabled: true,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: defaultSystemFieldsIsLoading,
    isError: defaultSystemFieldsIsError,
    data: defaultSystemFields,
    error: defaultSystemFieldsError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'default-fields', 'card', 'cardType', cardtype],
    queryFn: async () => {
      let dfFields = await getDefaultFields('card')
      let workDfFields: any = []
      dfFields.forEach((e: any) => {
        if (e.cardTypes && !e.cardTypes.includes(cardtype)) return
        workDfFields.push({...e, type: {key: e.type}})
      })
      return workDfFields
    },
    enabled: !systemFormData && !systemFormIsError,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: customFieldsIsLoading,
    isError: customFieldsIsError,
    data: customFields,
    error: customFieldsError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'custom-fields', 'card', 'cardType', cardtype],
    queryFn: async () => {
      let cfields = await getCustomFields('card', cardtype)
      let workCFields: any = []
      cfields.forEach((e: any) => {
        workCFields.push({...e, name: 'custom_' + e.id})
      })
      return workCFields
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadLabels = () => {
    let labels: Array<any> = []
    if (!card) return labels
    if (card.labels == undefined) return labels
    for (let i = 0; i < card.labels?.length; i++) {
      labels.push({label: card.labels[i].name, value: card.labels[i].id})
    }
    return labels
  }

  const loadCustomFields = (entityCustomFields: any, customFieldsCheck: any) => {
    let fields: any = {}
    if (!entityCustomFields) return fields

    for (let i = 0; i < entityCustomFields.length; i++) {
      let useCField = entityCustomFields[i]

      let formatReturn = formatCustomFieldDbDataToInput(useCField, customFieldsCheck)

      fields['custom_' + useCField.id] = formatReturn
    }

    return fields
  }

  const [dataForEdit, setDataForEdit] = useState<any>(null)

  const [fieldEditable, setFieldEditable] = useState<any>({
    field: '',
  })

  const labelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let labels = await getLabels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (labels && labels != undefined) {
        for (let i = 0; i < labels.length; i++) {
          list.push({label: labels[i].name, value: labels[i].id})
        }
      }
      resolve(list)
    })
  const debounceLabelsOptions = debounce(labelsOptions, 500)

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const peopleOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let peop = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (peop && peop.data != undefined) {
        for (let i = 0; i < peop?.data?.length; i++) {
          list.push({label: peop.data[i].name, value: peop.data[i].id})
        }
      }
      resolve(list)
    })
  const debouncePeopleOptions = debounce(peopleOptions, 500)

  const ticketCategoriesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let tkc = await getTicketCategories(
        'page=1&perPage=20&search=' + encodeURIComponent(inputValue)
      )
      if (tkc && tkc.data != undefined) {
        for (let i = 0; i < tkc?.data?.length; i++) {
          list.push({label: tkc.data[i].name, value: tkc.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceTicketCategoriesOptions = debounce(ticketCategoriesOptions, 500)

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels = await getFunnels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels?.length; i++) {
          list.push({label: funnels[i].name, value: funnels[i].id})
        }
      }
      resolve(list)
    })
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)

  const mountSelectLoadOptions = (field: any) => {
    switch (field.name) {
      case 'labelIds':
        return debounceLabelsOptions
      case 'responsibleId':
        return debounceResponsiblesOptions
      case 'funnelId':
        return debounceFunnelsOptions
      case 'ticketCategoryId':
        return debounceTicketCategoriesOptions
    }

    switch (field.type?.key) {
      case 'person':
        return debouncePeopleOptions
      default:
        return undefined
    }
  }

  const needDisableField = (field: any) => {
    if (card && ['funnelId', 'funnelStepId', 'responsibleId'].includes(field.name)) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (customFields === undefined) return undefined //Aguardar para ver se tem campos customizados
    if (dataForEdit) return undefined

    setDataForEdit({
      ...card,
      companyId: card?.company ? {label: card.company.name, value: card.company.id} : undefined,
      ticketCategoryId: card?.ticketCategory
        ? {label: card.ticketCategory.name, value: card.ticketCategory.id}
        : undefined,
      responsibleId: card?.responsible
        ? {label: card.responsible.name, value: card.responsible.id}
        : undefined,
      contactId: card?.contact ? {label: card.contact.name, value: card.contact.id} : undefined,
      funnelId: card?.funnel ? {label: card.funnel.name, value: card.funnel.id} : undefined,
      funnelStepId: card?.funnelStep
        ? {label: card.funnelStep.title, value: card.funnelStep.id}
        : undefined,
      labelIds: loadLabels(),
      ...loadCustomFields(card?.customFields, customFields),
    })
  }, [systemFormIsLoading, defaultSystemFieldsIsLoading, customFieldsIsLoading])

  if (systemFormIsLoading || defaultSystemFieldsIsLoading || customFieldsIsLoading || !dataForEdit)
    return <ListLoading />

  if (systemFormIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  if (defaultSystemFieldsIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  const handleShowActionsEditForm = (field: string, values: any, isSubmitting: boolean) => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          flexDirection: 'row',
          gap: '15px',
          marginTop: '4px',
          fontWeight: 'bold',
          marginBottom: '2px',
        }}
      >
        <Button
          type='submit'
          size='sm'
          disabled={isSubmitting}
          variant='wl-custom-primary-collor'
          style={{
            padding: '4px 25px 4px 25px',
            fontWeight: 'bold',
          }}
          onClick={() => {}}
        >
          {!isSubmitting ? 'Salvar' : <Spinner size='sm' color='white' />}
        </Button>
        <strong
          className='btn btn-sm btn-wl-custom-link btn-wl-custom-hover-danger'
          style={{cursor: 'pointer'}}
          onClick={() => {
            setFieldEditable({
              field: '',
            })
          }}
        >
          Cancelar
        </strong>
      </div>
    )
  }

  return (
    <>
      <Formik
        initialValues={dataForEdit}
        validationSchema={editDataSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true)

          let auxValues: any = {...values}
          if (!auxValues.title)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Título é Obrigatório.',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (!auxValues.funnelId)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Funil é Obrigatório',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (!auxValues.funnelStepId)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Etapa do Funil é Obrigatório',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (auxValues.id == null) delete auxValues.id

          if (auxValues.companyId && auxValues.companyId.value) {
            auxValues.companyId = auxValues.companyId.value
          } else {
            auxValues.companyId = null
          }

          if (auxValues.contactId && auxValues.contactId.value) {
            auxValues.contactId = auxValues.contactId.value
          } else {
            auxValues.contactId = null
          }

          if (auxValues.ticketCategoryId && auxValues.ticketCategoryId.value) {
            auxValues.ticketCategoryId = auxValues.ticketCategoryId.value
          } else {
            auxValues.ticketCategoryId = null
          }

          if (auxValues.funnelId && auxValues.funnelId.value) {
            auxValues.funnelId = auxValues.funnelId.value
          }

          if (auxValues.responsibleId && auxValues.responsibleId.value) {
            auxValues.responsibleId = auxValues.responsibleId.value
          } else {
            auxValues.responsibleId = null
          }

          if (auxValues.funnelStepId && auxValues.funnelStepId.value) {
            auxValues.funnelStepId = auxValues.funnelStepId.value
          }

          if (auxValues.status != undefined) {
            auxValues.status = auxValues.status ? 1 : 0
          }

          if (auxValues.labelIds) {
            let auxLabels: Array<number> = []
            for (let i = 0; i < auxValues.labelIds.length; i++) {
              auxLabels.push(auxValues.labelIds[i].value)
            }
            auxValues.labelIds = auxLabels
          }

          let customFieldsValue = []
          let objKeys = Object.keys(auxValues)
          for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i].includes('custom_')) {
              let useField = auxValues[objKeys[i]]
              let idCustom = objKeys[i].replace('custom_', '')

              let valueCustom = formatCustomFieldInputDataToDb(
                {
                  id: idCustom,
                  value: useField,
                },
                customFields
              )

              if (valueCustom === undefined) {
                console.log('Falha ao formatar valor do campo customizado')
                continue
              }

              customFieldsValue.push({
                id: idCustom,
                value: valueCustom,
              })
            }
          }
          auxValues.customFields = customFieldsValue

          try {
            let opUpSert = null

            if (isNotEmpty(card?.id)) {
              opUpSert = await updateCard(auxValues)

              if (funnelDispatch)
                funnelDispatch({
                  type: 'update-step-card',
                  data: opUpSert,
                })
            } else {
              opUpSert = await createCard(auxValues)

              if (funnelDispatch)
                funnelDispatch({
                  type: 'add-step-card',
                  data: opUpSert,
                })
            }

            Swal.fire('Registro salvo com Sucesso!!', '', 'success')
            setFieldEditable({field: ''})
            // resetForm()
            // handleClose()
            // refetch()
          } catch (ex: any) {
            console.error(ex)

            let errorDetected = ''
            if (ex.response?.data?.key && ex.response?.data?.message) {
              errorDetected = ex.response?.data?.message
            } else if (ex.response?.data?.message && Array.isArray(ex.response?.data?.message)) {
              if (ex.response?.data?.message.includes('title should not be empty'))
                errorDetected = 'Campo Título é Obrigatório'
              else if (ex.response?.data?.message.includes('funnel should not be empty'))
                errorDetected = 'Campo Funil é Obrigatório'
              else if (ex.response?.data?.message.includes('funnelStep should not be empty'))
                errorDetected = 'Campo Etapa do Funil é Obrigatório'
            }

            Swal.fire({
              title: 'Opss..',
              text: errorDetected ? errorDetected : 'Houve um problema ao salvar.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div
              // className='d-flex flex-column scroll-y me-n7 pe-7 gap-8'
              className='d-flex flex-column scroll-y me-n7 pe-5 gap-1'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {systemFormData &&
                systemFormData.fields.map((field: any, index: number) => {
                  if (field?.name == fieldEditable?.field) {
                    return (
                      <>
                        <FieldTypeInput
                          key={index}
                          field={field}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values[field.name]}
                          selectLoadOptions={mountSelectLoadOptions(field)}
                          setFieldValue={props.setFieldValue}
                          disabled={needDisableField(field)}
                        />

                        {handleShowActionsEditForm(field?.name, props.values, props.isSubmitting)}
                      </>
                    )
                  } else {
                    return (
                      <div key={'row' + index}>
                        {' '}
                        <BoxEditable
                          title={field?.label}
                          value={formatFieldInputDataToView(props?.values[field?.name], field)}
                          setControlFormEdit={setFieldEditable}
                          controlFormEdit={fieldEditable}
                          field={field?.name}
                        />
                      </div>
                    )
                  }
                })}

              {!systemFormData &&
                defaultSystemFields &&
                defaultSystemFields.map((field: any, index: number) => {
                  if (field?.name == fieldEditable?.field) {
                    return (
                      <>
                        <FieldTypeInput
                          key={index}
                          field={field}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values[field.name]}
                          selectLoadOptions={mountSelectLoadOptions(field)}
                          setFieldValue={props.setFieldValue}
                          disabled={needDisableField(field)}
                        />

                        {handleShowActionsEditForm(field?.name, props.values, props.isSubmitting)}
                      </>
                    )
                  } else {
                    return (
                      <div key={'row' + index}>
                        <BoxEditable
                          title={field?.label}
                          value={formatFieldInputDataToView(props?.values[field?.name], field)}
                          setControlFormEdit={setFieldEditable}
                          controlFormEdit={fieldEditable}
                          field={field?.name}
                        />
                      </div>
                    )
                  }
                })}

              {!systemFormData &&
                customFields &&
                customFields.map((field: any, index: number) => {
                  if (field?.name == fieldEditable?.field) {
                    return (
                      <>
                        <FieldTypeInput
                          key={index}
                          field={field}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values[field.name]}
                          selectLoadOptions={mountSelectLoadOptions(field)}
                          setFieldValue={props.setFieldValue}
                          disabled={needDisableField(field)}
                        />
                        {handleShowActionsEditForm(field?.name, props.values, props.isSubmitting)}
                      </>
                    )
                  } else {
                    return (
                      <div key={'row' + index}>
                        <BoxEditable
                          title={field?.label}
                          value={formatFieldInputDataToView(props?.values[field?.name], field)}
                          setControlFormEdit={setFieldEditable}
                          controlFormEdit={fieldEditable}
                          field={field?.name}
                        />
                      </div>
                    )
                  }
                })}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            {/* <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-sm  btn-primary'
                data-kt-users-modal-action='submit'
                disabled={isLoading || props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(props.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
            {/* end::Actions */}
            {(props.isSubmitting || isLoading) && <ListLoading />}
          </form>
        )}
      </Formik>
    </>
  )
}

export {CardsForm}
