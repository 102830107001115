import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, FieldArray, FormikProvider, Formik, Field} from 'formik'
import {isNotEmpty, toAbsoluteUrl, KTIcon} from '../../../../../_metronic/helpers'
import {initialData, TerminationReason, TERMINATION_REASON_TYPES} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {createTerminationReason, updateTerminationReason} from '../core/_requests'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import SelectAsync from 'react-select/async'
import {getFunnels} from '../../../funnel/core/_requests'
import debounce from 'debounce-promise'
type Props = {
  isLoading: boolean
  terminationReason: TerminationReason
}

const editDataSchema = Yup.object().shape({})

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    minHeight: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
}

const TerminationReasonEditModalForm: FC<Props> = ({terminationReason, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [dataForEdit] = useState<TerminationReason>({
    ...terminationReason,
    name: terminationReason.name || initialData.name,
    type: terminationReason.type || undefined,
    subreasons: terminationReason.subreasons,
    funnels: terminationReason.funnels,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels: any = await getFunnels(
        'page=1&perPage=20&status=1&search=' + encodeURIComponent(inputValue)
      )
      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels?.length; i++) {
          list.push({label: funnels[i].name, value: funnels[i].id})
        }
      }
      resolve(list)
    })
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)

  return (
    <>
      <Formik
        initialValues={dataForEdit}
        validationSchema={editDataSchema}
        onSubmit={async (values, {setSubmitting}) => {
          const dataToSend = {...values}
          dataToSend.funnelIds = values.funnels?.map((option) => option.value)
          setSubmitting(true)
          try {
            if (isNotEmpty(dataToSend.id)) {
              await updateTerminationReason(dataToSend)
            } else {
              await createTerminationReason(dataToSend)
            }
            cancel(true)
          } catch (ex: any) {
            console.error(ex)

            let errorDetected = ''

            if (ex.response?.data?.key && ex.response?.data?.message) {
              errorDetected = ex.response?.data?.message
            } else if (ex.response?.data?.message && Array.isArray(ex.response?.data?.message)) {
              if (ex.response?.data?.message.includes('name should not be empty'))
                errorDetected = 'Campo Nome é Obrigatório'
              else if (ex.response?.data?.message.includes('type should not be empty'))
                errorDetected = 'Campo Tipo é Obrigatório'
            }
            Swal.fire({
              title: 'Opss..',
              text: errorDetected
                ? errorDetected
                : 'Houve um problema ao salvar o motivo de finalização.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'Nome do Motivo '}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <input
                    placeholder=''
                    type='text'
                    name='name'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    className={clsx('form-control fieldset-input')}
                    autoComplete='off'
                    style={{paddingRight: '3.5rem'}}
                    disabled={props.isSubmitting || isLoading}
                  />
                </InputFieldSet>
              </div>

              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'Tipo '}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <select
                    name='type'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.type}
                    data-control='select2'
                    data-hide-search='true'
                    className={clsx(
                      'form-control form-select form-select-sm form-select-white fieldset-input'
                    )}
                    disabled={props.isSubmitting || isLoading || terminationReason.id != undefined}
                  >
                    <option value=''></option>
                    <option value={TERMINATION_REASON_TYPES.WIN}>Ganho</option>
                    <option value={TERMINATION_REASON_TYPES.LOST}>Perda</option>
                    <option value={TERMINATION_REASON_TYPES.SOLVED}>Resolvido</option>
                    <option value={TERMINATION_REASON_TYPES.UNSOLVED}>Não Resolvido</option>
                  </select>
                </InputFieldSet>
              </div>

              <div className='fv-row mb-7'>
                <InputFieldSet label={'Funis'}>
                  <SelectAsync
                    menuPortalTarget={document.body}
                    placeholder={''}
                    noOptionsMessage={() => 'Sem Funil'}
                    name='funnels'
                    loadOptions={debounceFunnelsOptions}
                    value={props.values.funnels}
                    cacheOptions
                    defaultOptions
                    isMulti
                    isDisabled={props.isSubmitting || isLoading}
                    onChange={(selectedOption) => {
                      if (selectedOption) props.setFieldValue('funnels', selectedOption)
                      else props.setFieldValue('funnels', [])
                    }}
                    className='fieldset-input'
                    styles={customStyles}
                  />
                </InputFieldSet>
              </div>

              {terminationReason.id != undefined && (
                <div className='fv-row mb-7'>
                  <InputFieldSet label={'Status'}>
                    <select
                      name='status'
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.status}
                      data-control='select2'
                      data-hide-search='true'
                      className='form-control form-select form-select-sm form-select-white fieldset-input'
                      defaultValue='1'
                    >
                      <option value='1'>Ativo</option>
                      <option value='0'>Inativo</option>
                    </select>
                  </InputFieldSet>
                </div>
              )}

              <h4 style={{marginBottom: '1rem', marginTop: '1rem'}}>Sub Motivos</h4>

              <FieldArray
                name='subreasons'
                render={(arrayHelpers) => (
                  <div>
                    {props.values.subreasons && props.values.subreasons.length > 0 ? (
                      props.values.subreasons.map((friend, subIndex) => (
                        <div key={subIndex} className='fv-row mb-4 d-flex align-items-center'>
                          <InputFieldSet label={'Nome do Sub Motivo'} style={{width: '100%'}}>
                            <Field
                              name={`subreasons.${subIndex}.name`}
                              className={'form-control fieldset-input'}
                              style={{paddingRight: '3.5rem'}}
                              disabled={props.isSubmitting || isLoading}
                            />
                          </InputFieldSet>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            style={{marginTop: '0.4rem', marginLeft: '1rem'}}
                            onClick={() => arrayHelpers.remove(subIndex)}
                          >
                            <span className='indicator-label'>-</span>
                          </button>
                        </div>
                      ))
                    ) : (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar Sub Motivo</span>
                      </a>
                    )}

                    {props.values.subreasons && props.values.subreasons.length > 0 && (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar Sub Motivo</span>
                      </a>
                    )}
                  </div>
                )}
              />
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-sm btn-wl-custom-primary-collor'
                data-kt-users-modal-action='submit'
                disabled={isLoading || props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(props.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(props.isSubmitting || isLoading) && <ListLoading />}
          </form>
        )}
      </Formik>
    </>
  )
}

export {TerminationReasonEditModalForm}
