import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Company, CompaniesQueryResponse, BusinessSegment} from './_models'
import {request} from '../../../services/crm-api/request'

const getCompanies = async (query: string): Promise<CompaniesQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/companies?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<CompaniesQueryResponse>) => d.data)
}

const getCompanyById = (id: ID): Promise<Company | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/companies/${id}?include_labels=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Company>) => response.data)
}

const createCompany = (dataCreate: Company): Promise<Company | undefined> => {
  return request(
    {
      method: 'post',
      url: `/companies`,
      data: dataCreate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Company>>) => response.data)
    .then((response: Response<Company>) => response.data)
}

const updateCompany = (dataUpdate: Company): Promise<Company | undefined> => {
  console.log('dataUpdate', dataUpdate)

  return request(
    {
      method: 'patch',
      url: `/companies/${dataUpdate.id}`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Company>>) => response.data)
    .then((response: Response<Company>) => response.data)
}

const deleteCompany = (id: ID): Promise<Company | undefined> => {
  return request(
    {
      method: 'DELETE',
      url: `/companies/${id}`,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}

const getBusinessSegments = async (query: string): Promise<Array<BusinessSegment>> => {
  return request(
    {
      method: 'GET',
      url: `/business-segments?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getLabels = async (query: string): Promise<Array<any>> => {
  return request(
    {
      method: 'GET',
      url: `/labels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getTasks = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/tasks?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const getNotes = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/notes?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const createNote = (dataForm: any): Promise<any> => {
  let data = {...dataForm}

  return request(
    {
      method: 'post',
      url: `/notes`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<any>) => response.data)
}

const deleteNote = (id: ID): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/notes/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?status=1&${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

export {
  getCompanies,
  getCompanyById,
  createCompany,
  updateCompany,
  deleteCompany,
  getBusinessSegments,
  getLabels,
  getTasks,
  getNotes,
  createNote,
  deleteNote,
  getUsers,
}
