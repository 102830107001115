import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Contact, ContactsQueryResponse} from './_models'
import {request} from '../../../services/crm-api/request'
import {Company} from '../../companies/core/_models'

const getContacts = async (query: string): Promise<ContactsQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/contacts?${query}&include_emails=true&include_phones=true&include_company=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<ContactsQueryResponse>) => d.data)
}

const getContactById = (id: ID): Promise<Contact | undefined> => {
  //Temporário
  return request(
    {
      method: 'GET',
      url: `/contacts/${id}?include_emails=true&include_phones=true&include_company=true&include_labels=true&include_responsible=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Contact>) => response.data)
}

const createContact = (dataForm: Contact): Promise<Contact | undefined> => {
  let data = {...dataForm}

  return request(
    {
      method: 'post',
      url: `/contacts`,
      data: data,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Contact>>) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const updateContact = (dataForm: Contact): Promise<Contact | undefined> => {
  let data = {...dataForm}

  return request(
    {
      method: 'patch',
      url: `/contacts/${dataForm.id}`,
      data: data,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Contact>>) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const deleteContact = (id: ID): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/contacts/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

const getCompanies = async (query: string): Promise<Response<Array<Company>>> => {
  return request(
    {
      method: 'GET',
      url: `/companies?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<Company>>>) => response.data)
}

const getLabels = async (query: string): Promise<Array<any>> => {
  return request(
    {
      method: 'GET',
      url: `/labels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data.data)
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?status=1&${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getTasks = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/tasks?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const getNotes = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/notes?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: any) => d.data)
}

const createNote = (dataForm: any): Promise<any> => {
  let data = {...dataForm}

  return request(
    {
      method: 'post',
      url: `/notes`,
      data: data,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<any>) => response.data)
}

const deleteNote = (id: ID): Promise<void> => {
  return request(
    {
      method: 'delete',
      url: `/notes/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

export {
  getContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContact,
  getCompanies,
  getLabels,
  getUsers,
  deleteNote,
  createNote,
  getNotes,
  getTasks,
}
