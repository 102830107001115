import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import SelectAsync from 'react-select/async'
import debounce from 'debounce-promise'
import {useTeamFormModalDispatch} from '../core/TeamFormModalContext'
import {useNavigate} from 'react-router-dom'
import {updateTeam, createTeam} from '../core/_requests'
import {getUsers} from '../../funnels/core/_requests'
import {getFunnels} from '../../funnels/core/_requests'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {isNotEmpty} from '../../../../../_metronic/helpers'

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  //   dropdownIndicator: (base:any) => ({
  //     ...base,
  //     padding: '3px',
  //     fontSize: '1rem'
  //   })
}
const ROLE_TYPES = [
  {
    id: 1,
    name: 'Administrador',
  },
  {
    id: 2,
    name: 'Supervisor',
  },
  {
    id: 3,
    name: 'Operador',
  },
  {
    id: 4,
    name: 'Analista',
  },
]

const editDataSchema = Yup.object().shape({})

const TeamForm: FC<any> = ({team, isLoading}) => {
  const {refetch} = useQueryResponse()
  const teamFormModalDispatch = useTeamFormModalDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    teamFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/teams')
    refetch()
  }

  const handleFormatUsers = () => {
    let users = []
    for (let i = 0; i < team?.users?.length; i++) {
      let use = team?.users[i]
      users.push({label: use?.name, value: use?.id})
    }

    return users
  }
  const handleFormatFunnels = () => {
    let funnels = []
    for (let i = 0; i < team?.funnels?.length; i++) {
      let use = team?.funnels[i]
      funnels.push({label: use?.name, value: use?.id})
    }

    return funnels
  }

  const [dataForEdit] = useState<any>({
    name: team?.name,
    users: team?.users ? handleFormatUsers() : null,
    external_id: team?.externalId ? team?.externalId : null,
    funnels: team?.funnels ? handleFormatFunnels() : [],
  })

  const usersOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceUsersOptions = debounce(usersOptions, 500)

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels: any = await getFunnels(
        'page=1&perPage=20&status=1&search=' + encodeURIComponent(inputValue)
      )
      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels?.length; i++) {
          list.push({label: funnels[i].name, value: funnels[i].id})
        }
      }
      resolve(list)
    })
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)
  const formik: any = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values: any, {setSubmitting, resetForm}) => {
      setSubmitting(true)

      let auxValues: any = {
        name: values.name,
        externalId: values?.external_id,
        userIds: values?.users?.map((e: any) => e?.value),
      }

      if (!auxValues.name)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Necessário Informar "Nome".',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      try {
        if (isNotEmpty(team?.id)) {
          await updateTeam(team?.id, auxValues)
        } else {
          await createTeam(auxValues)
        }

        Swal.fire('Registro salvo com Sucesso!', '', 'success')

        resetForm()
        handleClose()
      } catch (ex: any) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao salvar o registro.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_team_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_team_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_team_header'
          data-kt-scroll-wrappers='#kt_modal_add_team_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Nome '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <input
                placeholder=''
                type='text'
                name='name'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
                defaultValue={formik?.values['name']}
                onChange={formik.handleChange}
              />
            </InputFieldSet>
          </div>
          <div className='fv-row mb-7'>
            <InputFieldSet label={<>{'ID Externo '}</>}>
              <input
                placeholder=''
                type='text'
                name='external_id'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                defaultValue={formik?.values['external_id']}
                onChange={formik.handleChange}
              />
            </InputFieldSet>
          </div>
          <div className='fv-row mb-7'>
            <InputFieldSet label={'Usuários'}>
              <SelectAsync
                menuPortalTarget={document.body}
                isMulti={true}
                placeholder={''}
                name='users'
                loadOptions={debounceUsersOptions}
                cacheOptions
                defaultOptions
                className='fieldset-input'
                onChange={(e: any) => {
                  formik.setFieldValue(`users`, e)
                }}
                styles={customStyles}
                defaultValue={formik?.values['users']}
                noOptionsMessage={() => 'Sem registros...Digite para buscar'}
              />
            </InputFieldSet>
          </div>

          {/* <div className='fv-row mb-7'>
            <InputFieldSet label={'Funis'}>
              <SelectAsync
                menuPortalTarget={document.body}
                isMulti={true}
                placeholder={''}
                name='funnels'
                loadOptions={debounceFunnelsOptions}
                cacheOptions
                defaultOptions
                className='fieldset-input'
                onChange={(e: any) => {
                  formik.setFieldValue(`funnels`, e)
                }}
                styles={customStyles}
                defaultValue={formik?.values['funnels']}
                noOptionsMessage={() => 'Sem registros...Digite para buscar'}
              />
            </InputFieldSet>
          </div> */}
        </div>
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-teams-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {TeamForm}
